.softcom_titulo{
    color: black;    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.FaStarOfLife{
    color: black;
    width: 4em;
    height: 4em;    
    margin-left: 1em;
    margin-bottom: 30px;
}

.container{
    display: flex;
    justify-content: center;                
}

