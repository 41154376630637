
.container_contact{
    display: flex;
    width: 100%;
    min-height: 90vh;    
    align-items: center;    
    flex-direction: column;
    align-content: center;
    background-color: #121212;
    color: #FFF;
    padding: 10%;
  }
  
  .title{
    margin-bottom: 14px;    
  }
  
  .form{
    max-width: 800px;    
    display: flex;
    flex-direction: column;    
    width: 50%;
    
  }
  
  .input{
    margin-bottom: 14px;
    height: 34px;
    border-radius: 4px;
    border: 0;
    padding: 0 8px;
  }
  
  .textarea{
    margin-bottom: 14px;
    border-radius: 4px;
    border: 0;
    padding: 8px;
    height: 94px;
    resize: none;
  }
  
  .button{
    height: 34px;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    background-color: #0fdbff;
    font-size: 18px;
    transition: background-color, transform 0.8s;
  }
  
  .button:hover{
    background-color: #0fe3ff;
    transform: scale(1.01);
  }